import _ from 'lodash'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

const CatalogMixin = {
  data() {
    return {
      mqShallShowLeftSidebar: false,
      fLoading: true,
      pLoading: true,
      products: [],
      itemView: 'list-view',
      itemViewOptions: [
        { icon: 'GridIcon', value: 'grid-view' },
        { icon: 'ListIcon', value: 'list-view' },
      ],
      sortBy: { text: this.$i18n.t('catalog.list.sorting.rating-desc'), value: 'reviews_count-desc' },
      sortByOptions: [
        { text: this.$i18n.t('catalog.list.sorting.id-desc'), value: 'id-desc' },
        { text: this.$i18n.t('catalog.list.sorting.rating-asc'), value: 'reviews_count-asc' },
        { text: this.$i18n.t('catalog.list.sorting.rating-desc'), value: 'reviews_count-desc' },
        { text: this.$i18n.t('catalog.list.sorting.subscribers-asc'), value: 'history_sum_subscribers-asc' },
        { text: this.$i18n.t('catalog.list.sorting.subscribers-desc'), value: 'history_sum_subscribers-desc' },
        { text: this.$i18n.t('catalog.list.sorting.views-asc'), value: 'history_sum_views-asc' },
        { text: this.$i18n.t('catalog.list.sorting.views-desc'), value: 'history_sum_views-desc' },
        { text: this.$i18n.t('catalog.list.sorting.price-asc'), value: 'prices_sum_price-asc' },
        { text: this.$i18n.t('catalog.list.sorting.price-desc'), value: 'prices_sum_price-desc' },
        { text: this.$i18n.t('catalog.list.sorting.er-asc'), value: 'history_sum_er-asc' },
        { text: this.$i18n.t('catalog.list.sorting.er-desc'), value: 'history_sum_er-desc' },
        { text: this.$i18n.t('catalog.list.sorting.cpv-asc'), value: 'history_sum_cpv-asc' },
        { text: this.$i18n.t('catalog.list.sorting.cpv-desc'), value: 'history_sum_cpv-desc' },
      ],
      filterOptions: {
        categories: null,
        stickers: null,
        formats: [
          '15min',
          '30min',
          '1day',
          '2days',
          '3days',
          'eternal',
          'repost',
        ],
        languages: [
          'ukrainian',
          'english',
          'russian',
        ],
        statuses: [
          { label: this.$t('general.statuses.enabled'), value: 'enabled' },
          { label: this.$t('general.statuses.disabled'), value: 'disabled' },
          { label: this.$t('general.statuses.pending'), value: 'pending' },
          { label: this.$t('general.statuses.approved'), value: 'approved' },
          { label: this.$t('general.statuses.rejected'), value: 'rejected' },
        ],
        views: [0, 1000000],
        subscribers: [0, 3000000],
        er: [0, 100],
        cpm: [0, 10000],
        price: [0, 150000],
        sex: [0, 100],
      },
      filterOptionsSelectedBase: {
        query: null,
        categories: [],
        stickers: [],
        formats: [],
        languages: [],
        status: null,
        views: [0, 1000000],
        subscribers: [0, 3000000],
        er: [0, 100],
        cpm: [0, 10000],
        price: [0, 150000],
        sex: [0, 100],
      },
      filterOptionsSelected: {
        query: null,
        categories: [],
        stickers: [],
        formats: [],
        languages: [],
        status: null,
        views: [0, 1000000],
        subscribers: [0, 3000000],
        er: [0, 100],
        cpm: [0, 10000],
        price: [0, 150000],
        sex: [0, 100],
      },
      currentPage: 1,
      meta: {
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 12,
        to: 1,
        total: 1,
      },
      adaptiveView: window.innerWidth > 1360 ? 'list-view' : 'grid-view',
      api: '/api/promo/products/',
      name: 'products-index',
    }
  },
  computed: {
    fUrl() {
      if (!['products-index', 'products-hot'].includes(this.name)) {
        return null
      }
      return (this.onlyDiscounts ?? '') + _.reduce(this.filterOptionsSelected, (result, value, key) => {
        if (value && Array.isArray(value) && value.length) {
          if (value[0] !== this.filterOptionsSelectedBase[key][0] || value[1] !== this.filterOptionsSelectedBase[key][1]) {
            result += `${key}:${_.join(value, ',')};`
          }
        } else if (value && !Array.isArray(value) && value.length) {
          result += `${key}:${value};`
        }
        return result
      }, '')
    },
    fParams() {
      return {
        sorting: this.sortBy.value,
        query: this.$route.query.query ?? null,
        filter: this.fUrl ?? null,
        per_page: this.meta.per_page,
        page: this.currentPage,
      }
    },
    canFlush() {
      return !_.isEqual(this.filterOptionsSelected, this.filterOptionsSelectedBase)
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const filterUrlString = to.query.filter || ''
      const filterOptionsSelected = {}

      if (filterUrlString) {
        const filters = filterUrlString.split(';').filter(filter => filter !== '')

        filters.forEach(filter => {
          const [key, value] = filter.split(':')
          if (key && value) {
            filterOptionsSelected[key] = value.split(',')
          }
        })
      }

      vm.filterOptionsSelected = {
        ...vm.filterOptionsSelected,
        ...filterOptionsSelected,
      }
    })
  },
  async beforeCreate() {
    this.$http.get('/api/promo/categories/', {
      params: {
        type: 'product',
        status: 'enabled',
      },
    })
      .then(response => {
        this.filterOptions.categories = this.$options.filters.transformForVSelect(response.data.data, 'id', 'title')
      })
    this.$http.get('/api/stickersFront/')
      .then(response => {
        this.filterOptions.stickers = response.data.data
        this.fLoading = false
      })
  },
  mounted() {
    window.addEventListener('resize', this.onResize)
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  watch: {
    '$route.query': {
      handler() {
        this.debFetchData(this)
      },
      deep: true,
      immediate: true,
    },
    filterOptionsSelected: {
      handler(filtersSelected) {
        let filterUrlString = ''
        _.each(Object.keys(filtersSelected), key => {
          if (!_.isEqual(filtersSelected[key], this.filterOptionsSelectedBase[key])) {
            const value = filtersSelected[key]
            if (value && Array.isArray(value) && value.length) {
              filterUrlString += `${key}:${_.join(value, ',')};`
            } else if (value) {
              filterUrlString += `${key}:${value};`
            }
          }
        })
        this.$router.push({ name: this.name, query: { ...this.$route.query, filter: filterUrlString || null } })
      },
      deep: true,
      immediate: true,
    },
    currentPage: {
      handler(page) {
        this.$router.push({ name: this.name, query: { ...this.$route.query, page } })
      },
      deep: true,
      immediate: true,
    },
    sortBy: {
      handler(newSort) {
        const params = {
          ...this.$route.query,
          sorting: newSort.value !== 'reviews_count-desc' ? newSort.value : null,
        }
        this.$router.push({ name: this.name, query: params })
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    onResize() {
      this.adaptiveView = window.innerWidth > 1360 ? 'list-view' : 'grid-view'
    },
    fetchData() {
      this.pLoading = true
      this.$http.get(this.api, { params: this.fParams })
        .then(response => {
          this.products = response.data.data
          this.meta = response.data.meta
          this.currentPage = response.data.meta.current_page
          this.pLoading = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    flushFilters() {
      this.filterOptionsSelected = { ...this.filterOptionsSelectedBase }
    },
    initSearch(search) {
      this.searchPosts(search, this)
    },
    searchPosts: _.debounce((search, vm) => {
      vm.$router.push({ name: vm.name, query: { ...vm.$route.query, query: search } })
    }, 350),
    debFetchData: _.debounce(vm => {
      vm.fetchData()
    }, 350),
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`${this.api}${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: this.$t('notifications.confirm.destroy.channel'),
        text: null,
      })
    },
    loadMore() {
      if (this.meta.current_page >= this.meta.last_page) return
      this.loadMoreData()
    },
    loadMoreData() {
      this.pLoading = true
      this.$http.get(this.api, { params: { ...this.fParams, ...{ page: this.meta.current_page + 1 } } })
        .then(response => {
          this.meta = response.data.meta
          this.products = _.concat(this.products, response.data.data)
          this.pLoading = false
        })
        .catch(() => {
          this.pLoading = false
        })
    },
  },
}

export default CatalogMixin
