<template>
  <b-card
    class="ecommerce-card"
    :class="{ 'moderate': product.status === 'pending' || product.status === 'rejected' || product.status === 'approved' || product.status === 'disabled', 'deleted': product.status === 'disabled' }"
    no-body
  >
    <div class="item-img text-center">
      <b-link :to="{ name: 'account:products-show', params: { slug: product.slug } }">
        <b-img
          :alt="`${product.title}-${product.id}`"
          fluid
          class="card-img-top"
          :src="$options.filters.mediaUrl(product, 'avatar', '320x320')"
        />
      </b-link>
    </div>

    <!-- Product Details -->
    <b-card-body class="body_desc">
      <h6 class="item-name">
        <b-link
          class="text-body"
          :to="{ name: 'account:products-show', params: { slug: product.slug } }"
        >
          {{ product.title }}
        </b-link>
        <b-card-text
          v-if="product.categories"
          class="item-company"
        >
          <b-badge
            v-for="(category, cin) in product.categories"
            :key="cin + '_' + 'cat'"
            pill
            class="mr-75"
            variant="light-primary"
          >
            {{ category.title }}
          </b-badge>
        </b-card-text>
      </h6>
      <b-card-text class="item-description">
        {{ product.exception }}
      </b-card-text>
      <ul class="item-stickers">
        <li
          v-b-tooltip.hover.v-primary
          class="item-stickers-item lang"
          :title="$t(`general.languages.${product.language}`)"
        >
          <b-img
            fluid
            :src="`/language/${product.language}.svg`"
            :title="$t(`general.languages.${product.language}`)"
          />
        </li>
        <li
          v-for="(sticker,sin) in product.stickers"
          :key="sin"
          v-b-tooltip.hover.v-primary
          class="item-stickers-item"
          :title="$options.filters.transl(sticker, 'title')"
        >
          <b-img
            fluid
            :src="$options.filters.mediaUrl(sticker, 'avatar', 'base')"
          />
        </li>
        <li
          v-b-tooltip.hover.v-primary
          class="item-stickers-item rate"
          :title="$t('catalog.item.labels.rating')"
        >
          <b-img
            fluid
            :src="`/icons/rating.png`"
          />
          <b-card-text class="ml-1">
            {{ product.rating }}
          </b-card-text>
        </li>
      </ul>
    </b-card-body>

    <!-- Product Info -->
    <b-card-body
      v-show="product.status !== 'disabled'"
      class="data_col"
    >
      <b-card
        no-body
        class="card-app-design mb-0"
      >
        <div class="design-planning-wrapper type-1-1-1">
          <div
            v-b-tooltip.hover.v-primary
            class="design-planning"
            :title="$t('catalog.item.descriptions.views')"
          >
            <p class="card-text mb-25">
              {{ $t('catalog.item.labels.views') }}
            </p>
            <h6 class="mb-0">
              {{ $options.filters.fFormat(getHistory.views) }}
            </h6>
          </div>
          <div
            v-b-tooltip.hover.v-primary
            class="design-planning"
            :title="$t('catalog.item.descriptions.er')"
          >
            <p class="card-text mb-25">
              {{ $t('catalog.item.labels.er') }}
            </p>
            <h6 class="mb-0">
              {{ $options.filters.numberFormat(getHistory.er) + '%' }}
            </h6>
          </div>
          <div
            v-b-tooltip.hover.v-primary
            class="design-planning"
            :title="$t('catalog.item.descriptions.cpm')"
          >
            <p class="card-text mb-25">
              {{ $t('catalog.item.labels.cpm') }}
            </p>
            <h6 class="mb-0">
              {{ $options.filters.moneyFormat(getHistory.cpv * 1000) }}
            </h6>
          </div>
        </div>
        <div class="design-planning-wrapper type-1-1">
          <div
            v-b-tooltip.hover.v-primary
            class="design-planning"
            :title="$t('catalog.item.descriptions.subscribers')"
          >
            <p class="card-text mb-25">
              {{ $t('catalog.item.labels.subscribers') }}
            </p>
            <h6 class="mb-0">
              {{ $options.filters.numberFormat(getHistory.subscribers) }}
            </h6>
          </div>
          <div
            v-b-tooltip.hover.v-primary
            class="design-planning"
            :class="product.sex"
            :title="$t('catalog.item.descriptions.sex_ratio')"
          >
            <p class="card-text mb-25 gender_icons">
              <span v-text="`Ч-${product.sex_ratio}%`" />
              <span v-text="`Ж-${100-product.sex_ratio}%`" />
            </p>
            <div class="line">
              <div
                class="blue"
                :style="`width:${product.sex_ratio}%;`"
              />
            </div>
          </div>
        </div>

        <b-alert
          variant="success"
          :show="product.status === 'approved'"
          class="mb-0"
        >
          <div
            class="alert-body"
            v-html="product.comment_status || $t('catalog.item.moderate.approved')"
          />
        </b-alert>
        <b-alert
          variant="primary"
          :show="product.status === 'pending'"
          class="mb-0"
        >
          <div
            class="alert-body"
            v-html="$t('catalog.item.moderate.pending')"
          />
        </b-alert>
        <b-alert
          variant="danger"
          :show="product.status === 'rejected'"
          class="mb-0"
        >
          <div
            class="alert-body"
            v-html="product.comment_status || $t('catalog.item.moderate.rejected')"
          />
        </b-alert>
      </b-card>
    </b-card-body>

    <!-- Product Actions -->
    <div
      v-show="product.status !== 'disabled'"
      class="item-options text-center"
    >
      <div class="buttons">
        <b-button
          variant="light"
          tag="a"
          :to="{ name: 'account:products-show', params: { slug: product.slug } }"
        >
          <feather-icon
            icon="EyeIcon"
          />
        </b-button>
        <b-button
          variant="primary"
          tag="a"
          :to="{ name: 'account:products-update', params: { slug: product.slug } }"
        >
          <feather-icon
            icon="EditIcon"
          />
        </b-button>
        <b-button
          variant="warning"
          @click="destroy(product.id)"
        >
          <feather-icon
            icon="XIcon"
          />
        </b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import _ from 'lodash'

export default {
  mixins: [GlobalMixin],
  props: {
    product: {
      type: Object,
      required: true,
    },
    hot: {
      type: Boolean,
      required: true,
    },
    destroy: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      priceType: '1day',
    }
  },
  computed: {
    selectedPrice() {
      const vm = this
      return _.filter(this.product.prices, o => o.type === vm.priceType)
    },
    selectedDiscount() {
      const vm = this
      return _.filter(this.product.discounts, o => o.type === vm.priceType)
    },
    getHistory() {
      let h = {
        subscribers: 0,
        views: 0,
        er: 0,
        cpv: 0,
      }
      const koef = this.koefOptions[this.priceType]
      if (this.product.historyLast) {
        h = {
          subscribers: this.product.historyLast.subscribers,
          views: this.product.historyLast.views * koef,
          er: ((this.product.historyLast.views * koef) / this.product.historyLast.subscribers) * 100,
          cpv: (this.selectedDiscount && this.selectedDiscount.length ? this.selectedDiscount[0].price : this.selectedPrice[0].price) / (this.product.historyLast.views * koef),
        }
      }
      return h
    },
  },
}
</script>

<style lang="scss" scoped>
.ecommerce-application {

  .ecommerce-card {
    overflow: visible !important;

    .item-img {
      padding: 10px !important;
      position: relative;

      img {
        display: block;
        width:100%;
        border-radius: 50%;
        box-shadow: 0 0 5px rgba(0,0,0,.2);
      }
    }

    .body_desc {
      .item-stickers {
        width: 100%;
        padding: 5px;
        background: rgba(238, 238, 238, 0.6);
        display: flex;
        gap:10px;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: space-between;
        margin:0 0;
        border-radius: 5px;
        order: 3;

        .item-stickers-item {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            max-width: 20px;
            max-height: 20px;
          }

          &.lang {
            img {
              border-radius: 50%;
            }
          }

          &.rate {
            width: 70px;
          }
        }
      }
    }

    .card-app-design {

      .design-planning-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr !important;
        align-items: stretch;
        gap:5px;
        margin:0 0;

        .design-planning {
          margin: 0 0 !important;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: help;

          &.disabled {
            filter:grayscale(1);
            pointer-events: none;
          }
        }

        &.type-1-1 {
          grid-template-columns: 1fr 1fr !important;
          margin:5px 0 0;

          .gender_icons {
            display: flex;
            justify-content: space-between;
            width: 100%;

            svg {
              width: 20px;
              height: 20px;
            }
          }

          .line {
            height: 4px;
            width: 100%;
            display: flex;
            background: #ea5455;
            border-radius: 2px;

            .blue {
              display: flex;
              height: 4px;
              background: #7367f0;
              border-radius: 2px;
            }
          }
        }
      }
    }

    .item-options {
      padding:1rem;

      .buttons {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 10px;
      }
    }

    &.moderate {

      .card-app-design {
        position: relative;

        .design-planning-wrapper {
          opacity: 0.1;
        }

        .alert {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          align-content: center;
          justify-content: center;
          padding: 1em;
          text-align: center;
        }
      }
    }

    &.deleted {
      opacity: 0.4;
      filter: grayscale(1);

      &:hover {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }

  .list-view {
    .ecommerce-card {
      margin-bottom: 1rem;
      //grid-template-columns: 1fr 2fr 2fr 1fr !important;

      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;

      .item-img {
        width: 140px;
        min-width: 140px;
        max-width: 140px;
      }

      .card-body {
        padding: 10px;
        border: none;

        .item-description {
          -webkit-line-clamp: 2;
          margin: 0 0 10px;
        }
      }

      .data_col {
        width: 300px;
        max-width: 300px;
        min-width: 300px;
        border-right: 1px solid #ebe9f1;
        border-left: 1px solid #ebe9f1;
      }

      .item-options {
        padding: 10px;
        width: 90px;
        max-width: 90px;
        min-width: 90px;

        .buttons {
          grid-template-columns: 1fr;
          gap:6px;

          .btn {
            padding: 10px;
          }
        }
      }
    }
  }

  .grid-view {
    .ecommerce-card {
      .item-img {
        width: 100px;
        min-width: 100px;
        max-width: 100px;
        min-height: 100px;
        max-height: 100px;
        position: absolute;
        left:0;
        top:0;
        z-index: 2;
      }

      .body_desc {
        min-height: 100px;
        //max-height: 100px;
        padding: 2px 1rem 2px 100px !important;

        .item-name {
          margin: 5px 0 0;
        }

        .item-description {
          margin: 0 0;
        }
      }

      .item-company {
        display: block !important;
      }

      .card-app-design {
        background-color: #f8f8f8;

        .design-planning-wrapper {
          gap:0;
          margin:0 0;

          .design-planning {
            margin: 0 0 !important;
            border: 1px solid #fff;
          }

          &.type-1-1 {
            margin:0 0;
          }
        }
      }

      .item-options {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        .card-text {
          display: none !important;
        }

        .item-price {
          display: block !important;
        }
      }
    }
  }
}
</style>
