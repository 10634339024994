<template>
  <div
    style="height: inherit"
    :class="{ 'is-loading': pLoading }"
  >

    <!-- ECommerce Header -->
    <section id="ecommerce-header">
      <div class="row">
        <div class="col-sm-12">
          <div class="ecommerce-header-items">
            <div class="result-toggler">
              <feather-icon
                icon="MenuIcon"
                class="d-block d-lg-none"
                size="21"
                @click="mqShallShowLeftSidebar = true"
              />
              <div class="search-results">
                {{ meta.total }} {{ $t('catalog.list.labels.results') }}
              </div>
            </div>
            <div class="view-options d-flex">

              <!-- Sort Button -->
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :text="sortBy.text"
                right
                variant="outline-primary"
              >
                <b-dropdown-item
                  v-for="sortOption in sortByOptions"
                  :key="sortOption.value"
                  @click="sortBy=sortOption"
                >
                  {{ sortOption.text }}
                </b-dropdown-item>
              </b-dropdown>

              <!-- Item View Radio Button Group  -->
              <b-form-radio-group
                v-model="itemView"
                class="ml-1 list item-view-radio-group d-none"
                buttons
                size="sm"
                button-variant="outline-primary"
              >
                <b-form-radio
                  v-for="option in itemViewOptions"
                  :key="option.value"
                  :value="option.value"
                >
                  <feather-icon
                    :icon="option.icon"
                    size="18"
                  />
                </b-form-radio>
              </b-form-radio-group>
            </div>
          </div>
        </div>
      </div>
    </section>

    <b-alert
      :show="!userData || !userData.telegram"
      variant="warning"
      class="p-1 mt-1"
      v-html="$t('info.bot.get')"
    />

    <!-- Overlay -->
    <div class="body-content-overlay" />

    <!-- Searchbar -->
    <div class="ecommerce-searchbar mt-1">
      <b-row>
        <b-col cols="12">
          <b-input-group class="input-group-merge">
            <b-form-input
              :placeholder="$t('catalog.list.labels.search')"
              class="search-product"
              @input="(val) => initSearch(val)"
            />
            <b-input-group-append is-text>
              <feather-icon
                icon="SearchIcon"
                class="text-muted"
              />
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12">
          <b-button
            variant="primary"
            tag="a"
            class="mt-1"
            block
            :to="{ name: 'account:products-store' }"
          >
            <feather-icon
              icon="PlusIcon"
            />
            {{ $t('catalog.item.create.btn') }}
          </b-button>
        </b-col>
      </b-row>
    </div>

    <!-- Prodcuts -->
    <section
      v-infinite-scroll="loadMore"
      :class="adaptiveView"
      infinite-scroll-disabled="pLoading"
      infinite-scroll-distance="10"
    >
      <Card
        v-for="product in products"
        :key="product.id"
        :product="product"
        :hot="false"
        :destroy="destroy"
      />
    </section>
  </div>
</template>

<script>
import GlobalMixin from '@mixins/GlobalMixin'
import CatalogMixin from '@mixins/CatalogMixin'
import Card from '@/views/account/channels/Card'

export default {
  components: {
    Card,
  },
  mixins: [GlobalMixin, CatalogMixin],
  data() {
    return {
      name: 'account:products-index',
      api: '/api/user/products/',
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr;
}

.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}

.is-loading {
  opacity: 0.5;
}
</style>
